import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

// components
import { VerticalForm, FormSelect, FormInput, FormDate, SingleUpload } from "components/";

import {
  useGetExpoBoothRepsQuery,
  useGetEmployeeQuery,
  useGetExpoBoothQuery,
  useCreateExpoBoothMutation,
  useUpdateExpoBoothMutation,
  useDeleteExpoBoothMutation,
} from "services/api";

import { toast } from "react-toastify";
import { IoWarningOutline } from "react-icons/io5";
import { useEffectOnce } from "usehooks-ts";

interface ModalRepsProps {
  slug?: string;
  booth: any;
  show: boolean;
  onHide: () => void;
}

const ModalReps = ({
  slug,
  booth,
  show,
  onHide,
}: ModalRepsProps) => {
  const [boothAssist, setBoothAssist] = useState('');
  const [updateExpoBoth, { isLoading: isLoadingUpdate }] = useUpdateExpoBoothMutation();

  const {
    data: repsList,
    isFetching: isFetchingReps,
    isLoading: isLoadingReps,
    isUninitialized: isUninitializedReps,
    isError: isErrorReps,
    error: errorReps,
    refetch: refetchReps,
  } = useGetExpoBoothRepsQuery(
    {
      slug
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  

  const {
    data: employeeList,
    isFetching: isFetchingEmployee,
    isLoading: isLoadingEmployee,
    isUninitialized: isUninitializedEmployee,
    isError: isErrorEmployee,
    error,
    refetch,
  } = useGetEmployeeQuery(
    {
      slug
    },
    {
      // pollingInterval: 100,
      // refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const validation = yupResolver(
    yup.object().shape({
      representative_code: yup.string().label('Representative name').required(),
      assistant_type: yup.mixed().label('Type'),
      assistant_name: yup.mixed().label('Assistant name'),
    })
  );
  
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      await updateExpoBoth({
        slug,
        ...values
      })
      .unwrap()
      .then((res) => {
        if(res.status){
          onHide()
          toast.success(res.message)
        }
      })
      .catch((error: any) => {
        console.log("error");
      });
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };
  
  useEffectOnce(() => {
    if (booth) {
      Object.keys(booth).map((key) => {
        setValue(key, booth[key]);
      });
    }
  });

  if(
    isFetchingReps ||
    isLoadingReps ||
    isUninitializedReps ||
    isErrorReps ||
    isFetchingEmployee ||
    isLoadingEmployee ||
    isUninitializedEmployee ||
    isErrorEmployee
  ){
    return (
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
        <ScaleLoader height={20} width={1} color="#b7b1dd" />
      </div>
    )
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Modal.Body className="p-4">
          {
            (isFetchingReps || isLoadingReps || isUninitializedReps || isErrorReps) ? (
              <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
                <ScaleLoader height={25} width={1} color="#b7b1dd" />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit((values: any) => onSubmit({ ...values }))}
                noValidate
              >
                <h4 className="text-left mt-n2 mb-n1 text-uppercase">
                  <i className="mdi mdi mdi-notebook-edit-outline me-1 font-20"></i>Please fill in the fields below </h4>
                <hr />
                <FormSelect
                  label="Representative Name"
                  name="representative_code"
                  placeholder=""
                  containerClass={"mb-3"}
                  defaultValue={booth?.representative_code || undefined}
                  options={(repsList?.result || []).map((item: any) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  onChange={(opt: any) => {
                    setValue('representative_code', opt.value);
                  }}
                  isClearable
                />
                <FormSelect
                  label="Booth Assistant (Optional)"
                  name="assistant_type"
                  placeholder=""
                  containerClass={"mb-3"}
                  defaultValue={booth?.assistant_type || undefined}
                  options={[
                    {
                      label: "Freelance", value: "Freelance",
                    },
                    {
                      label: "JACK Study Abroad Employee", value: "Employee",
                    },
                  ]}
                  // disabled={isLoading}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  onChange={(opt: any) => {
                    setValue('assistant_name', '')
                    setError('assistant_name', { type: 'custom', message: 'Assistant name is a required field' })
                    setBoothAssist(opt.value)
                    setValue('assistant_type', opt.value)
                  }}
                />
                {
                  boothAssist == 'Employee' ?  (
                    <FormSelect
                      name="assistant_name"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={booth?.assistant_name || undefined}
                      options={(employeeList?.result || []).map((item: any) => {
                        return {
                          label: item.name,
                          value: item.name,
                        };
                      })}
                      // disabled={isLoading}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      onChange={(opt: any) => {
                        setValue('assistant_name', opt.value)
                      }}
                    />
                  ) : (
                    <FormInput
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      // label="Name"
                      type="text"
                      name="assistant_name"
                      placeholder=""
                      containerClass={"mb-3"}
                      defaultValue={booth?.assistant_name}
                    />
                  )
                }
                <div className="text-end">
                  <Button
                    variant="success"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    disabled={isLoadingUpdate}
                  >
                    Confirm
                  </Button>
                </div>
              </form>
            )
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalReps;
