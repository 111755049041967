import { combineReducers } from "redux";
// import theme from './theme/themeSlice'
// import auth from './auth'
// import base from './base'
// import { api } from '../features/api'
import { api } from "services/api";
import Auth from "./auth/authSlice";
import Layout from "./layout/reducers";

const combinedReducer = combineReducers({
  Auth,
  Layout,
  // theme,
  // auth,
  // base,
  // ...asyncReducers,
  // [api.reducerPath]: api.reducer,
  [api.reducerPath]: api.reducer,
});
const rootReducer = () => (state: any, action: any) => {
  if (action.type == '@@auth/RESET') {
    localStorage.removeItem('persist:jsa-booth');
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof combinedReducer>;
