import React, { useState, useEffect, InputHTMLAttributes } from "react";
import { Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import AsyncSelect from 'react-select/async';

import { FieldErrors, Control } from "react-hook-form";

interface FormSelectAsyncProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  control?: Control<any>;
  setValue?: any;
  setError?: any;
  clearErrors?: any;
  getValues?: any;
  className?: string;
  labelClassName?: string;
  containerClass?: string;
  refCallback?: any;
  children?: any;
  rows?: string;
  defaultOptions?: any;
  loadOptions: any;
  onChange?: any;
  value?: any;
  defaultValue?: any;
  isClearable?: boolean;
  isMulti?: boolean;
}

const FormSelectAsync = ({
  label,
  type,
  name,
  placeholder,
  register,
  errors,
  control,
  setValue,
  setError,
  clearErrors,
  getValues,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  rows,
  defaultOptions,
  loadOptions,
  onChange,
  value,
  defaultValue,
  isClearable,
  isMulti,
  
  ...otherProps
}: FormSelectAsyncProps) => {
  // handle input type
  const comp =
    type === "textarea" ? "textarea" : type === "select" ? "select" : "input";
  const [currentValue, setCurrentValue] = useState("");
  const [isClear, setIsClear] = useState(false);

  const onChangeOption = (opt: any, actionType: any) => {
    if (onChange !== undefined) {
      onChange(opt,actionType);
    }
    if (setValue !== undefined) {
      setValue(name, opt.value, true);
    }
    setCurrentValue(opt.value);
    if (opt.value) {
      clearErrors(name);
    }
  };

  const getDefaultValue = () => {
    if(isMulti && defaultOptions){
      return defaultOptions.filter((item: any) => String(defaultValue).split(',').includes(item.value))
    }
    return defaultValue;
  }

  useEffect(() => {
    // console.log(defaultValue)
    if (setValue !== undefined) {
      setValue(name, defaultValue, true);
    }
    setCurrentValue(defaultValue);
    if (defaultValue) {
      clearErrors(name);
    }
    // setError(name, { type: 'custom', message: 'custom message' })
  }, []);

  return (
    <>
      <Form.Group className={containerClass}>
        {label ? (
          <Form.Label className={labelClassName}>{label}</Form.Label>
        ) : null}

        <Form.Control
          // type={type}
          type="hidden"
          value={currentValue}
          placeholder={placeholder}
          name={name}
          id={name}
          as="input"
          ref={(r: HTMLInputElement) => {
            if (refCallback) refCallback(r);
          }}
          className={className}
          isInvalid={errors && errors[name] ? true : false}
          {...(register ? register(name) : {})}
          rows={rows}
          {...otherProps}
          autoComplete={name}
        >
          {children ? children : null}
        </Form.Control>

        <AsyncSelect
          menuPortalTarget={document.body}
          noOptionsMessage={() => "Search by name..."}
          // cacheOptions
          className="react-select react-select-container"
          classNamePrefix="react-select"
          placeholder={placeholder}
          // value={
          //   options.filter((opt: any) => opt.value == value) || undefined
          // }
          defaultValue={getDefaultValue() || []}
          styles={{
            control: (base: any, state: any) => ({
              ...base,
              boxShadow: state.isFocused
                ? errors && errors[name]
                  ? "0px 0px 4px 1px rgba(241, 85, 108, 0.4)"
                  : "#f1556c"
                : "#dee2e6",
              // state.isFocused can display different borderColor if you need it
              borderColor: errors && errors[name] ? "#f1556c" : "#dee2e6",
              // overwrittes hover style
              "&:hover": {
                borderColor: errors && errors[name] ? "#f1556c" : "#dee2e6",
              },
            }),
            menuPortal: (base: any, state: any) => ({
              ...base,
              zIndex: 9999
            }),
          }}
          defaultOptions={isClear ? defaultOptions : []}
          loadOptions={loadOptions}
          onChange={(opt: any, actionType: any) => {
            onChangeOption(opt || {},actionType);
            if(actionType == 'clear'){
              setIsClear(true)
            } else {
              setIsClear(false)
            }
            console.log(actionType)
          }}
          isClearable={isClearable}
          isMulti={isMulti}
        />

        {errors && errors[name] ? (
          <Form.Control.Feedback type="invalid">
            {errors[name]["message"]}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormSelectAsync;
