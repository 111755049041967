import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import { VerticalForm, FormInput, FormDate, FormSelect, FormSelectAsync } from "components/";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { useEffectOnce } from "usehooks-ts";

import {
  useGetCourseLevelQuery,
  useGetCourseMajorQuery,
  useGetCourseFullNameQuery,
  useGetCommentQuery,
  useUpdateOrCreateCommentMutation,
  useGetOptionQuery,
  useSearchAutocompleteMutation,
} from "services/api";

const yearOptions = () => {
  let sy = new Date().getFullYear()
  let ey = sy + 5;
  let yrs = [];

  for (var i = sy; i <= ey; i++) {
    yrs.push({
      value: sy,
      label: sy,
    });
    sy++;
  }
  return yrs;
}

const validation = yupResolver(
  yup.object().shape({
    email: yup.string().transform((value) => (value == null ? '' : value)).email().required('').label('Email'),
    phone: yup.string().transform((value) => (value == null ? '' : value)).min(10, 'Please fill a valid phone number').max(20).required('').label('Phone'),
    school_code: yup.string().transform((value) => (value == null ? '' : value)).label('School').required(''),
    school_name: yup.mixed().default('').when('school_code', {
      is: 'Other',
      then: yup.string().label('School Name').required(''),
    }),
    grade_code: yup.string().transform((value) => (value == null ? '' : value)).label('Grade').required(''),
    note: yup.string().required(),
  })
);

const FormComment = ( {
  slug,
  layout,
  setLayout,
  expoRegistrant,
  booth,
  onHide,
} : {
  slug?: string,
  layout: string,
  setLayout: any,
  expoRegistrant: any,
  booth: any;
  onHide?: any;
}) => {
  const [isHot, setIsHot] = useState(expoRegistrant.is_hot || false);
  
  const [gradeCode, setGradeCode] = useState(expoRegistrant?.pref_grade_code || '')
  const [schoolCode, setSchoolCode] = useState((expoRegistrant?.pref_school_code) ? {
    value: expoRegistrant?.pref_school_code,
    label: expoRegistrant?.pref_school_name
  } : (
    expoRegistrant?.pref_school_name ? {label: 'Other',value: 'Other'} : undefined
  ))

  const { data: gradeList, isLoading: isLoadingGrade } = useGetOptionQuery({type: 'GRD'});
  const [searchAutocomplete, { isLoading: isLoadingSearchAutocomplete }] = useSearchAutocompleteMutation();

  const loadSchoolOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if(inputValue.length >= 2 ){
      searchAutocomplete({
        type: 'SCH',
        // filter: {
        //   grade_code: getValues()['grade_code'],
        // },
        search: inputValue,
        limit: 100,
      })
        .unwrap()
        .then((res: any) => {
          console.log(res?.result)
          if(res?.result?.length){
            callback(res?.result);
          } else {
            callback([{
              label: "Other",
              value: "Other"
            }]);
          }
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    }
  };

  const {
    data: courseLevelList,
    isFetching: isFetchingCourseLevel,
    isLoading: isLoadingCourseLevel,
    isUninitialized: isUninitializedCourseLevel,
    isError: isErrorCourseLevel,
    error: errorCourseLevel,
    refetch: refetchCourseLevel,
  } = useGetCourseLevelQuery(
    {
      per_page: 9999
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  const {
    data: courseMajorList,
    isFetching: isFetchingCourseMajor,
    isLoading: isLoadingCourseMajor,
    isUninitialized: isUninitializedCourseMajor,
    isError: isErrorCourseMajor,
    error: errorCourseMajor,
    refetch: refetchCourseMajor,
  } = useGetCourseMajorQuery(
    {
      per_page: 9999
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );
  const {
    data: courseFullNameList,
    isFetching: isFetchingCourseFullName,
    isLoading: isLoadingCourseFullName,
    isUninitialized: isUninitializedCourseFullName,
    isError: isErrorCourseFullName,
    error: errorCourseFullName,
    refetch: refetchCourseFullName,
  } = useGetCourseFullNameQuery(
    {
      per_page: 9999
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
      // refetchOnReconnect: true,
      // skip: false,
    }
  );

  const { comment, errorComment, isUninitialized: isUninitializedComment, isLoading: isLoadingComment, isFetching: isFetchingComment, isError: isErrorComment } = useGetCommentQuery(
    {
      slug: slug,
      expo_registrant_code: expoRegistrant?.expo_registrant_code,
      expo_comment_code: expoRegistrant?.expo_comment_code,
    },
    {
      selectFromResult: ({ data, error, isUninitialized, isLoading, isFetching, isError }) => ({
        comment: data?.result
          ? data.result.find((el: any) => el.code == expoRegistrant?.expo_comment_code)
          : null,
        errorComment: error,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const [updateOrCreateComment, { isLoading: isLoadingUpdate }] = useUpdateOrCreateCommentMutation();

  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
    trigger,
  } = methods;

  const onSubmit = async (values: any) => {
    await updateOrCreateComment({
      ...values,
      is_hot: isHot,
      slug,
      leads_code: expoRegistrant.leads_code,
      school_code: schoolCode?.label != 'Other' ? schoolCode?.value : null,
    })
    .unwrap()
    .then((res: any) => {
      onHide()
    })
    .catch((error: any) => {
      console.log("error");
    });
  };

  const onClickHot = () => {
    setIsHot(!isHot)
  }

  useEffectOnce(() => {
    if(expoRegistrant?.pref_grade_code){
      // console.log('grade_code exists')
      setValue('grade_code',expoRegistrant?.pref_grade_code);
    } else {
      // setValue('country_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
    }

    if(expoRegistrant?.pref_school_code){
      // console.log('school_code exists')
      setValue('school_code',expoRegistrant?.pref_school_code);
    } else if(expoRegistrant?.pref_school_name){
      setSchoolCode({
        label: "Other",
        value: "Other"
      })
      setValue('school_code','Other');
    } else {
      // setValue('country_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
    }

    if(comment?.note){
      // console.log('grade_code exists')
      setValue('note',comment?.pref_grade_code);
    } else {
      // setValue('country_code','INS2400'+faker.number.int({ min: 1, max: 9 }));
    }
  })

  useEffect(() => {
    console.log(errors)
  }, [errors])

  if(isUninitializedComment || isLoadingComment || isFetchingComment || isErrorComment){
    return (
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
        <ScaleLoader height={25} width={1} color="#b7b1dd" />
      </div>
    )
  }

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit(values))}
      noValidate
      // className="d-flex flex-column align-items-start"
    >
      <FormInput
        className="bg-light"
        label="JACK Study Abroad ID"
        type="text"
        name="leads_code"
        placeholder=""
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.leads_code}
        disabled
      />
      <FormInput
        className="bg-light"
        label="Full Name"
        type="text"
        name="full_name"
        placeholder="Enter full name"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.full_name}
        disabled
      />
      <div className="row">
        <div className="col">
          <FormInput
            className="bg-light"
            label="Branch"
            type="text"
            name="branch_name"
            placeholder=""
            containerClass={"mb-3"}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            defaultValue={expoRegistrant?.branch_name}
            disabled
          />
        </div>
        <div className="col">
          <FormInput
            className="bg-light"
            label="Counselor"
            type="text"
            name="counselor_name"
            placeholder=""
            containerClass={"mb-3"}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            defaultValue={expoRegistrant?.counselor_name}
            disabled
          />
        </div>
      </div>
      <FormInput
        label="Email"
        type="text"
        name="email"
        placeholder="e.g. agushan392@email.com"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.email}
        // disabled
      />
      <FormInput
        label="Phone"
        type="text"
        name="phone"
        placeholder="e.g. +62813456789876"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.phone}
        // disabled
      />
      
      <div className="row">
        <div className="col-12">
          <FormSelect
            label="Grade"
            name="grade_code"
            placeholder="e.g. Year 10 / O Level"
            containerClass={"mb-3"}
            // defaultValue={getValues()['grade_code'] || data?.grade_code || undefined}
            defaultValue={gradeCode || undefined}
            options={(gradeList?.result || [])}
            // disabled={isLoading}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
            onChange={(opt: any) => {
              // setSchoolCode(undefined) // {}
              // setValue('school_code', undefined)
              setGradeCode(opt.value)
              setValue('grade_code', opt.value)
            }}
            isClearable
          />
        </div>
        <div className="col-12">
          <FormSelectAsync
            className="w-100"
            label="School"
            name="school_code"
            placeholder="e.g. SMA N 11 Jakarta"
            containerClass={"mb-3"}
            defaultValue={schoolCode}
            // defaultValue={(getValues()['school_code'] || data?.school_code) ? {
            //   value: getValues()['school_code'] || data?.school_code,
            //   label: getValues()['school_name'] || data?.school_name
            // } : undefined}
            // defaultOptions={data?.school_code ? [{
            //   value: data?.school_code,
            //   label: data?.kecamatan_name
            // }] : undefined}
            loadOptions={loadSchoolOptions}
            // disabled={isLoading}
            register={register}
            control={control}
            errors={errors}
            // setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            onChange={(option: any, actionname: any) => {
              setValue('school_code', option.value)
              if(option.label == 'Other'){
                setValue('school_name', '')
              } else {
                setValue('school_name', option.label)
              }
              setSchoolCode(option)
              if(actionname.action == 'clear'){
                setSchoolCode(undefined)
                setValue('school_code', '')
                setValue('school_name', '')
              }
            }}
            isClearable
          />
        </div>
          
        {
          (schoolCode?.label == 'Other') &&
          <FormInput
            className=""
            label="Enter school name"
            type="text"
            name="school_name"
            placeholder="e.g. One World International School"
            containerClass={"mb-3"}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            defaultValue={expoRegistrant?.pref_school_name || undefined}
            // onChange={(e: any) => {
            //   if(e.target.value == ''){
            //     setValue('school_code', '')
            //     alert('asd')
            //   }
            // }}
            // disabled
          />
        }
      </div>
      <FormSelect
        label="Course Level"
        subLabel={`Preferred: ${expoRegistrant?.pref_course_level_name || '-'}`}
        name="course_level_code"
        placeholder=""
        containerClass={"mb-3"}
        defaultValue={comment?.course_level_code || expoRegistrant?.pref_course_level_code || undefined}
        options={(courseLevelList?.result || []).map((item: any) => {
          return {
            label: item.name,
            value: item.code,
          };
        })}
        // disabled={isLoading}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        // onChange={(opt: any) => {
        //   setCalcMarketingBranding(opt.value);
        // }}
      />
      <FormSelect
        label="Study Area"
        subLabel={`Preferred: ${expoRegistrant?.pref_course_major_name || '-'}`}
        name="course_major_code"
        placeholder=""
        containerClass={"mb-3"}
        defaultValue={comment?.course_major_code || expoRegistrant?.pref_course_major_code || undefined}
        options={(courseMajorList?.result || []).map((item: any) => {
          return {
            label: item.name,
            value: item.code,
          };
        })}
        // disabled={isLoading}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        // onChange={(opt: any) => {
        //   setCalcMarketingBranding(opt.value);
        // }}
      />
      {/* <FormSelect
        label="Course Full Name"
        subLabel={`Preferred: ${expoRegistrant?.pref_course_full_name_name || '-'}`}
        name="course_full_name_code"
        placeholder=""
        containerClass={"mb-3"}
        defaultValue={comment?.course_full_name_code || expoRegistrant?.pref_course_full_name_code || undefined}
        options={(courseFullNameList?.result || []).map((item: any) => {
          return {
            label: item.name,
            value: item.code,
          };
        })}
        // disabled={isLoading}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        // onChange={(opt: any) => {
        //   setCalcMarketingBranding(opt.value);
        // }}
      /> */}
      <FormSelect
        label="Year of Study"
        subLabel={`Preferred: ${expoRegistrant?.pref_planning_year || '-'}`}
        name="planning_year"
        placeholder=""
        containerClass={"mb-3"}
        defaultValue={comment?.planning_year || expoRegistrant?.pref_planning_year || undefined}
        options={yearOptions()}
        // disabled={isLoading}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        // onChange={(opt: any) => {
        //   setCalcMarketingBranding(opt.value);
        // }}
      />
      {/* <FormInput
        rows="10"
        label="Comment/Notes"
        labelSuffix="Comment/Notes"
        type="textarea"
        name="note"
        placeholder=""
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={comment?.note || ''}
      /> */}
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>
          <div className="d-flex align-items-center">
            <span>Comment/Notes</span>
            {/* <i className={`mdi mdi-fire font-20 cursor-pointer ${isHot && 'text-danger'}`} onClick={(e) => { onClickHot() }}></i> */}
            <div className="d-flex justify-content-start align-items-center mx-1">
              {
                isHot ? (
                  <div onClick={(e) => { onClickHot() }}><FaStar size="16" color="#fcd53f" style={{marginBottom: '2px'}} /></div>
                ) : (
                  <div onClick={(e) => { onClickHot() }}><FaRegStar size="16" style={{marginBottom: '2px'}} /></div>
                )
              }
            </div>
          </div>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          {...register('note')}
          isInvalid={errors && errors['note'] ? true : false}
          defaultValue={comment?.note || expoRegistrant?.note || undefined}
        />
        {errors && errors['note'] ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors['note']["message"]}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group> 

      <hr />
      <div className="d-flex justify-content-start w-100">
        <div>
          <Button
            variant="info"
            type="submit"
            className="waves-effect waves-light"
            disabled={isLoadingUpdate}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* <div className="text-end">
        <Button
          variant="success"
          type="submit"
          className="waves-effect waves-light me-1"
        >
          Save
        </Button>
        <Button
          variant="danger"
          className="waves-effect waves-light"
          onClick={onHide}
        >
          Continue
        </Button>
      </div> */}
    </form>
  )
}

export default FormComment;