import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import moment from "moment";

// components
import ModalReps from "./ModalReps";
import ModalAdd from "./ModalAdd";
import ModalComment from "./ModalComment";
import List from "./List";

import {
  useGetCourseLevelQuery,
  useGetCourseMajorQuery,
  useGetCourseFullNameQuery,
  useGetExpoQuery,
  useGetExpoBoothQuery,
} from "services/api";

const Home = () => {
  let { slug } = useParams();
  const navigate = useNavigate();
  const [currentLeads, setCurrentLeads] = useState();

  const [modalShowReps, setModalShowReps] = useState<boolean>(false);
  const closeModalReps = () => setModalShowReps(false);
  const openModalReps = () => {
    setModalShowReps(true)
  };

  const [modalShowAdd, setModalShowAdd] = useState<boolean>(false);
  const closeModalAdd = () => setModalShowAdd(false);
  const openModalAdd = () => {
    setCurrentLeads(undefined)
    setModalShowAdd(true)
  };

  const [modalShowComment, setModalShowComment] = useState<boolean>(false);
  const closeModalComment = () => setModalShowComment(false);
  const openModalComment = (data: any) => {
    console.log('data',data)
    setCurrentLeads(data)
    setModalShowComment(true)
  };
  
  useGetCourseLevelQuery({per_page: 9999},{});
  useGetCourseMajorQuery({per_page: 9999},{});
  useGetCourseFullNameQuery({per_page: 9999},{});

  const { expo, errorExpo, isUninitialized: isUninitializedExpo, isLoading: isLoadingExpo, isFetching: isFetchingExpo, isError: isErrorExpo } = useGetExpoQuery(
    {
      slug: slug
    },
    {
      selectFromResult: ({ data, error, isUninitialized, isLoading, isFetching, isError }) => ({
        expo: data?.result
          ? data.result.find((el: any) => el.slug == slug)
          : null,
        errorExpo: error,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      // refetchOnMountOrArgChange: true,
      skip: false,
    }
  );
  const { booth, errorExpoBooth, isUninitialized: isUninitializedExpoBooth, isLoading: isLoadingExpoBooth, isFetching: isFetchingExpoBooth, isError: isErrorExpoBooth } = useGetExpoBoothQuery(
    {
      slug
    },
    {
      selectFromResult: ({ data, error, isUninitialized, isLoading, isFetching, isError }) => ({
        booth: data?.result
          ? data.result.find((el: any) => el.expo_slug == slug)
          : null,
        errorExpoBooth: error,
        isUninitialized : isUninitialized ,
        isLoading: isLoading,
        isFetching: isFetching,
        isError: isError,
      }),
      // refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  useEffect(() => {
    if(booth){
      if(booth.representative_name == null || booth?.representative_name == ''){
        setModalShowReps(true)
      }
    }
  }, [booth])

  if(isUninitializedExpo || isLoadingExpo || isFetchingExpo || isErrorExpo || isUninitializedExpoBooth || isLoadingExpoBooth || isFetchingExpoBooth || isErrorExpoBooth){
    return (
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '200px'}}>
        <ScaleLoader height={25} width={1} color="#b7b1dd" />
      </div>
    )
  }
  
  return (
    <>
      <Row>
        <Col xs md={"8"}>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <img
                  className="d-flex me-3 rounded"
                  src={expo.image}
                  width="100"
                />
                <div className="w-100">
                  <h4 className="mt-0 mb-1">{expo.name}</h4>
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-barcode-scan me-1 font-18" />
                        </strong>
                        <span className="">#{expo.code}</span>
                      </div>
                      <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-google-maps me-1 font-18" />
                        </strong>
                        <span className="">{expo.venue}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-timer-outline me-1 font-18" />
                        </strong>
                        <span className="">
                          {expo.start_time} - {expo.end_time}
                        </span>
                      </div>
                      <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-calendar me-1 font-18" />
                        </strong>
                        <span className="">{expo.date}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-map-legend me-1 font-18" />
                        </strong>
                        <span className="">
                          {expo.address}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="">
                      <div className="input-group input-group-sm">
                        <span className="input-group-text">
                          Registration URL
                        </span>
                        <input
                          placeholder=""
                          name="booth_email"
                          autoComplete="booth_email"
                          type="text"
                          id="booth_email"
                          className="form-control"
                          value={expo.registration_url}
                        />
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip
                              style={{ position: "fixed" }}
                              id={`tooltip-${"top"}`}
                            >
                              Copy
                            </Tooltip>
                          }
                        >
                          <CopyToClipboard
                            text={expo.registration_url}
                            onCopy={() => {
                              toast.success("Copied", {
                                autoClose: 100,
                                hideProgressBar: true,
                              });
                            }}
                          >
                            <button
                              type="button"
                              className="border-1 btn btn-outline-light"
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </button>
                          </CopyToClipboard>
                        </OverlayTrigger>
                        <a
                          target="_blank"
                          href={expo.registration_url}
                          className="border-1 btn btn-outline-light"
                        >
                          <i className="fe-external-link"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="align-self-start">
                  <Button
                    // size="lg"
                    variant="success"
                    type="submit"
                    className="waves-effect waves-light text-nowrap"
                    onClick={() => openModalAdd()}
                    disabled={!booth.representative_name}
                  >
                    <span>Add visitor</span>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs md={"4"}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-start">
                {/* <img
                  className="d-flex me-3 rounded-circle avatar-lg"
                  src={booth.avatar}
                  alt=""
                /> */}
                <div className="w-100">
                  <h4 className="mt-0 mb-1">
                    Hi, {booth.representative_name || "Representative"}
                  </h4>
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong>
                          <i className="mdi mdi-account-supervisor me-1 font-18" />
                        </strong>
                        <span className="">
                          Assistant: {booth.assistant_name || "-"}{" "}
                          {booth.assistant_type
                            ? "(" + booth.assistant_type + ")"
                            : ""}
                        </span>
                      </div>
                      {/* <div className="col-12 col-sm-auto d-inline-flex justify-content-start align-items-center">
                        <strong><i className="mdi mdi-calendar me-1 font-18"/></strong>
                        <span className="">Saturday, 18 Nov 2023</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => {
                      openModalReps();
                    }}
                  >
                    <h2 className="p-0 m-0">
                      <i className="mdi mdi-cog-outline"></i>
                    </h2>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs md={"12"}>
          <List
            onClickAction={(action: any, item: any) => {
              // navigate(`/jsb/md/master-expoRegistrant/${item.code}`);
              openModalComment(item);
            }}
          />
        </Col>
      </Row>

      {booth && (
        <ModalReps
          slug={slug}
          booth={booth}
          show={modalShowReps}
          onHide={closeModalReps}
        />
      )}

      {modalShowAdd && (
        <ModalAdd
          booth={booth}
          slug={slug}
          show={modalShowAdd}
          onHide={closeModalAdd}
        />
      )}

      {modalShowComment && (
        <ModalComment
          booth={booth}
          expoRegistrant={currentLeads}
          slug={slug}
          show={modalShowComment}
          onHide={closeModalComment}
        />
      )}
    </>
  );
};

export default Home;
