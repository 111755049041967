import React, { useState } from "react";
import { Modal, Button, Form, InputGroup, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import ScaleLoader from "react-spinners/ScaleLoader";

// components
import { VerticalForm, FormInput, FormDate, FormSelect, FormSelectAsync } from "components/";
import FormCheck from "./FormCheck"
import FormComment from "./FormComment"

import {
  useCheckExpoRegistrantMutation,
  useUpdateOrCreateCommentMutation,
} from "services/api";

type NewType = any;

interface LeadsProps {
  leads_code?: any;
  expo_comment_code?: any;
  avatar?: any;
  full_name?: any;
  email?: any;
  phone?: any;
  dob?: any;
  course_level_code?: any;
  course_level_name?: any;
  course_major_code?: any;
  course_major_name?: any;
  course_full_name_code?: any;
  course_full_name_name?: any;
  note?: any;
  is_visited?: any;
  visited_at?: any;
}
interface ModalAddProps {
  slug?: string;
  booth?: string;
  show: boolean;
  onHide: () => void;
  // onSubmit: (value: NewType) => void;
}

const validation = yupResolver(
  yup.object().shape({
    // name: yup.string().nullable(),
    // amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Field is required"),
  })
);


const FormLeads = ( {
  layout,
  setLayout,
  expoRegistrant,
  onHide,
} : {
  layout: string,
  setLayout: any,
  expoRegistrant: LeadsProps,
  onHide: any;
}) => {
  const methods = useForm({ resolver: validation });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (values: any) => {
    console.log('here')
    setLayout('comment')
    try {
      // await updateExpoBoth({
      //   slug,
      //   ...values
      // })
      // .unwrap()
      // .then((res) => {
      //   if(res.status){
      //     onHide()
      //     toast.success(res.message)
      //   }
      // })
      // .catch((error: any) => {
      //   console.log("error");
      // });
    } catch (err: any) {
      console.log(err);
      // if (err?.data?.errors) {
      //   Object.keys(err?.data?.errors)?.forEach((field) => {
      //     err.data.errors[field]?.forEach((message: string) => {
      //       toast.error(message, { autoClose: 10000 });
      //     });
      //   });
      // }
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit(values))}
      noValidate
      // className="d-flex flex-column align-items-start"
    >
      {
        expoRegistrant.expo_comment_code &&
        <Alert
          variant="danger"
        >
          <strong>{expoRegistrant.full_name}</strong> had already come to the booth.
        </Alert>
      }
      <FormInput
        className="bg-light"
        label="JACK Study ID"
        type="text"
        name="code"
        placeholder=""
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.leads_code}
        disabled
      />
      <FormInput
        className="bg-light"
        label="Full name"
        type="text"
        name="email"
        placeholder="Enter email"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.full_name}
        disabled
      />
      <FormInput
        className="bg-light"
        label="Email address"
        type="text"
        name="email"
        placeholder="Enter email"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.email}
        disabled
      />
      <FormInput
        className="bg-light"
        label="Phone"
        type="text"
        name="phone"
        placeholder="Enter phone number"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.phone}
        disabled
      />
      <FormInput
        className="bg-light"
        label="Date of Birth"
        type="text"
        name="dob"
        placeholder="Enter phone number"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={expoRegistrant?.dob}
        disabled
      />
      {/* <FormDate
        label="Date of Birth"
        type="date"
        name="dob"
        placeholder="Enter date of birth"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        disabled
      /> */}
      {/* <FormInput
        label="Address"
        type="textarea"
        name="address"
        placeholder="Enter address number"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <FormInput
        label="City"
        type="text"
        name="city"
        placeholder="Enter city"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <FormInput
        label="Province"
        type="text"
        name="province"
        placeholder="Enter province"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <FormSelect
        label="Tax/Service"
        type="text"
        name="toe_ids"
        placeholder=""
        containerClass={"mb-3"}
        defaultValue={undefined}
        options={[
          {
            value: '12345', label: '12345 - Jakarta',
          },
          {
            value: '67891', label: '67891 - Jakarta',
          },
        ]}
        // disabled={isLoadingAdd || isLoadingUpdate}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        onChange={(e: any) => {
          // let data = e.map((item: any) => {
          //   return item.value;
          // })
          // setValue("toe_ids", data);
          // setCurrent({
          //   ...current,
          //   toe_ids: data,
          // });
        }}
        isClearable
      />
      <FormInput
        label="Prev Education Name"
        type="text"
        name="last_education_name"
        placeholder="Enter last_education_name"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <FormInput
        label="Prev Education Level"
        type="text"
        name="last_education_level"
        placeholder="Enter last_education_level"
        containerClass={"mb-3"}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <FormSelect
        label="Year of Study"
        type="text"
        name="fi_calculate_marketing"
        placeholder=""
        containerClass={"mb-3"}
        // defaultValue={current?.fi_calculate_marketing || undefined}
        options={[
          { value: "2023", label: "2023" },
          { value: "2024", label: "2024" },
          { value: "2025", label: "2025" },
          { value: "2026", label: "2026" },
          { value: "2027", label: "2027" },
        ]}
        // disabled={isLoading}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        // onChange={(opt: any) => {
        //   setCalcMarketingBranding(opt.value);
        // }}
      /> */}

      <hr />
      <div className="d-flex justify-content-start w-100 gap-2">
        <Button
          variant="link"
          type="button"
          className="waves-effect waves-light"
          onClick={(e) => { setLayout('default') }}
        >
          Back
        </Button>
        <Button
          variant="info"
          type="submit"
          className="waves-effect waves-light"
        >
          Confirm
        </Button>
      </div>

      {/* <div className="text-end">
        <Button
          variant="success"
          type="submit"
          className="waves-effect waves-light me-1"
        >
          Save
        </Button>
        <Button
          variant="danger"
          className="waves-effect waves-light"
          onClick={onHide}
        >
          Continue
        </Button>
      </div> */}
    </form>
  )
}

const ModalAdd = ({
  slug,
  booth,
  show,
  onHide,
  // onSubmit,
}: ModalAddProps) => {
  const [layout, setLayout] = useState('default');

  const [expoRegistrant, setExpoRegistrant] = useState<LeadsProps>({});

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // size={layout == 'default' ? 'sm' : undefined}
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">Add Visitor</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {
            layout == 'default' &&
            <FormCheck
              slug={slug}
              layout={layout}
              setLayout={setLayout}
              expoRegistrant={expoRegistrant}
              setExpoRegistrant={setExpoRegistrant}
              booth={booth || undefined}
              onHide={onHide}
            />
          }
          {
            layout == 'expoRegistrant' &&
            <FormLeads
              layout={layout}
              setLayout={setLayout}
              expoRegistrant={expoRegistrant}
              onHide={onHide}
            />
          }
          {
            (layout == 'comment' && expoRegistrant) &&
            <FormComment
              slug={slug}
              layout={layout}
              setLayout={setLayout}
              expoRegistrant={expoRegistrant}
              booth={booth || undefined}
              onHide={onHide}
            />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAdd;
