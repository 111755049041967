import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Card, Row, Col, Button } from "react-bootstrap";

interface PaginationCardProps {
  meta: any;
  activePage: any;
  setActivePage: any;
  perPage: any;
  setPerPage: any;
}

const PaginationCard = ({ meta, perPage, setPerPage, activePage, setActivePage }: PaginationCardProps) => {
  /**
   * pagination count , index
   */
  const [pageCount, setPageCount] = useState<number>(meta.last_page);
  const [pageIndex, setPageIndex] = useState<number>(
    meta.current_page - 1
  );

  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
    {
      text: "200",
      value: 200,
    },
  ];

  useEffect(() => {
    setPageCount(meta.last_page);
    setPageIndex(meta.current_page);
  }, [meta.last_page, meta.current_page]);

  /**
   * get filter pages
   */
  const filterPages = useCallback(
    (visiblePages: any, totalPages: number) => {
      return visiblePages.filter((page: any) => page <= pageCount);
    },
    [pageCount]
  );

  /**
   * handle visible pages
   */
  const getVisiblePages = useCallback(
    (page: number | null, total: number) => {
      if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
      } else {
        if (page! % 5 >= 0 && page! > 4 && page! + 2 < total) {
          return [1, page! - 1, page!, page! + 1, total];
        } else if (page! % 5 >= 0 && page! > 4 && page! + 2 >= total) {
          return [1, total - 3, total - 2, total - 1, total];
        } else {
          return [1, 2, 3, 4, 5, total];
        }
      }
    },
    [filterPages]
  );

  /**
   * handle page change
   * @param page - current page
   * @returns
   */
  const changePage = (page: number) => {
    // const activePage = pageIndex + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = getVisiblePages(page, pageCount);
    setVisiblePages(filterPages(visiblePages, pageCount));

    // meta.gotoPage(page - 1);
    setActivePage(page);
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(null, pageCount);
    setVisiblePages(visiblePages);
  }, [pageCount, getVisiblePages]);

  const [visiblePages, setVisiblePages] = useState<number[]>(
    getVisiblePages(null, pageCount)
  );
  // const activePage: number = pageIndex + 1;

  return (
    <Card>
      <Card.Body style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <div className="d-lg-flex align-items-center text-center">
          {sizePerPageList.length > 0 && (
            <div className="d-inline-block me-3">
              <label className="me-1">Display :</label>
              <select
                value={perPage}
                onChange={(e: any) => {
                  setActivePage(1)
                  setPerPage(Number(e.target.value));
                }}
                className="form-select form-select-sm d-inline-block w-auto"
              >
                {(sizePerPageList || []).map((pageSize, index) => {
                  return (
                    <option key={index} value={pageSize.value}>
                      {pageSize.text}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          <span className="me-3">
            Page{" "}
            <strong>
              {meta.current_page} of {meta.last_page}
            </strong>{" "}
            , Total{" "}
            <strong>
              {meta.total}
            </strong>
          </span>

          <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
            <label className="form-label">Go to page : </label>
            <input
              type="number"
              value={pageIndex}
              min="1"
              onChange={(e: any) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                // meta.gotoPage(page);
                changePage(page)
                setPageIndex(meta.current_page);
              }}
              className="form-control form-control-sm w-25 ms-1 d-inline-block"
            />
          </span>

          <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
            <li
              key="prevpage"
              className={classNames("page-item", "paginate_button", "previous", {
                disabled: activePage === 1,
              })}
              onClick={() => {
                if (activePage === 1) return;
                changePage(activePage - 1);
              }}
            >
              <Link to="#" className="page-link">
                <i className="mdi mdi-chevron-left"></i>
              </Link>
            </li>
            {(visiblePages || []).map((page, index, array) => {
              return array[index - 1] + 1 < page ? (
                <React.Fragment key={page}>
                  <li className="page-item disabled d-none d-xl-inline-block">
                    <Link to="#" className="page-link">
                      ...
                    </Link>
                  </li>
                  <li
                    className={classNames(
                      "page-item",
                      "d-none",
                      "d-xl-inline-block",
                      {
                        active: activePage === page,
                      }
                    )}
                    onClick={(e: any) => changePage(page)}
                  >
                    <Link to="#" className="page-link">
                      {page}
                    </Link>
                  </li>
                </React.Fragment>
              ) : (
                <li
                  key={page}
                  className={classNames(
                    "page-item",
                    "d-none",
                    "d-xl-inline-block",
                    {
                      active: activePage === page,
                    }
                  )}
                  onClick={(e: any) => changePage(page)}
                >
                  <Link to="#" className="page-link">
                    {page}
                  </Link>
                </li>
              );
            })}
            <li
              key="nextpage"
              className={classNames("page-item", "paginate_button", "next", {
                disabled: activePage === meta.last_page,
              })}
              onClick={() => {
                if (activePage === meta.last_page) return;
                changePage(activePage + 1);
              }}
            >
              <Link to="#" className="page-link">
                <i className="mdi mdi-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PaginationCard;
