import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";

// components
import { VerticalForm, FormInput, FormDate, FormSelect, FormSelectAsync } from "components/";
import FormComment from "./FormComment"

import {
  useCheckExpoRegistrantMutation,
  useUpdateOrCreateCommentMutation,
} from "services/api";

type NewType = any;

interface LeadsProps {
  leads_id?: any;
  code?: any;
  avatar?: any;
  title?: any;
  full_name?: any;
  email?: any;
  phone?: any;
  dob?: any;
  address?: any;
  city?: any;
  province?: any;
  country?: any;
  zip_code?: any;
  parents_name?: any;
  parents_phone?: any;
  parents_email?: any;
  grade_id?: any;
  grade_code?: any;
  grade_name?: any;
  school_id?: any;
  school_code?: any;
  school_name?: any;
  course_level_id?: any;
  course_level_code?: any;
  course_level_name?: any;
  course_major_id?: any;
  course_major_code?: any;
  course_major_name?: any;
  course_full_name_id?: any;
  course_full_name_code?: any;
  course_full_name_name?: any;
  institution_id?: any;
  institution_code?: any;
  institution_name?: any;
  planning_year?: any;
  branch_id?: any;
  branch_code?: any;
  branch_name?: any;
  counselor_id?: any;
  counselor_code?: any;
  counselor_name?: any;
  status_id?: any;
  status_code?: any;
  status_name?: any;
  marketing_source_id?: any;
  marketing_source_code?: any;
  marketing_source_name?: any;
}
interface ModalCommentProps {
  slug?: string;
  booth?: any;
  expoRegistrant?: any;
  show: boolean;
  onHide: () => void;
  // onSubmit: (value: NewType) => void;
}

const validation = yupResolver(
  yup.object().shape({
    // name: yup.string().nullable(),
    // amount: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required("Field is required"),
  })
);

const ModalComment = ({
  slug,
  booth,
  expoRegistrant,
  show,
  onHide,
  // onSubmit,
}: ModalCommentProps) => {
  const [layout, setLayout] = useState('default');

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <FormComment
            slug={slug}
            layout={layout}
            setLayout={setLayout}
            expoRegistrant={expoRegistrant}
            booth={booth || undefined}
            onHide={onHide}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComment;
