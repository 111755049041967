import React, { useEffect, useState } from "react";
import { Row, Col, Button, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

// components
import BTable from "components/BTable";

import {
  useGetExpoRegistrantQuery,
} from "services/api";

const CellName = ({ data }:{ data: any }) => {
  return (
    <div className="d-flex justify-content-start align-items-center gap-1">
      {
        data.avatar &&
        <div style={{height: '30px', width: '30px'}}>
          <img height={30} width={30} src={data.avatar} alt="" className="h-auto border rounded-circle" />
        </div>
      }
      <div className="text-body fw-semibold">
        <div className="d-flex gap-1">
          <span>{data.title} {data.full_name}</span>
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip style={{position:"fixed"}} id={`tooltip-${'top'}`}>
                Copy
              </Tooltip>
            }
          >
            <CopyToClipboard text={data.full_name}
              onCopy={() => {
                toast.success('Copied', {autoClose: 100, hideProgressBar: true})
              }}>
                <strong><i className="mdi mdi-content-copy cursor-pointer"/></strong>
            </CopyToClipboard>
          </OverlayTrigger>
        </div>
        <div className="d-flex align-items-center gap-1 mb-1">
          {
            data.expo_comment_code ? (
              <Badge bg="success" className="fw-normal">
                Attended
              </Badge>
            ) : (
              <Badge bg="light" className="fw-normal text-secondary">
                Registered
              </Badge>
            )
          }
          <div className="text-muted">
            {
              data.kota_name &&
              <span>{data.kota_name}</span>
            }
            {
              data.kota_name &&
              <span>, {data.kota_name}</span>
            }
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          {
            data.branch_name &&
            <Badge bg="blue" className="fw-normal">
              {data.branch_name}
            </Badge>
          }
          <div className="d-flex align-items-center">
            <img
              className="rounded-circle"
              src={data.counselor_avatar}
              alt=""
              width="20"
            />
            <span className="ps-1 text-dark font-12">
              {data.counselor_name}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const CellID = ({ data }:{ data: any }) => {
  return (
    <div className="d-flex gap-1">
      <span>{data.leads_code}</span>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip style={{position:"fixed"}} id={`tooltip-${'top'}`}>
            Copy
          </Tooltip>
        }
      >
        <CopyToClipboard text={data.leads_code}
          onCopy={() => {
            toast.success('Copied', {autoClose: 100, hideProgressBar: true})
          }}>
            <strong><i className="mdi mdi-content-copy cursor-pointer"/></strong>
        </CopyToClipboard>
      </OverlayTrigger>
    </div>
  )
}

const CellComment = ({ data }:{ data: any }) => {
  if(data.expo_comment_code){
    return (
      <div className="d-flex flex-column">
        {/* <div className="font-12 d-flex gap-1">
          <div className="font-12 fw-semibold">
            {data.institution_name} #{data.institution_code}
          </div>
        </div> */}
        <div className="font-12 d-flex align-items-center gap-1">
          <Badge bg="primary" className="">{data.representative_name}</Badge>
          <Badge bg="soft-primary" className="text-dark">{data.assistant_name}</Badge>
          {
            data.is_hot &&
            // <i className="mdi mdi-fire font-16 cursor-pointer text-danger me-n1"></i>
            <>⭐</>
          }
        </div>
        <div className="font-15 text-wrap fw-semibold">
          {/* {data.note} */}
          {
            data.note.length > 70 ? (
              <>
                {data.note.split(' ').slice(0, 15).join(' ') + "..."}
              </>
            ) : (
              <>{data.note}</>
            )
          }
        </div>
        <div className="font-12 d-flex justify-content-start align-items-start gap-1">
          {
            data.course_level_name &&
            <Badge bg="transparent" className="badge-outline-secondary badge">CL: {data.course_level_name}</Badge>
          }
          
          {
            data.course_major_name &&
            <Badge bg="transparent" className="badge-outline-secondary badge">CM: {data.course_major_name}</Badge>
          }
          
          {
            data.course_full_name_name &&
            <Badge bg="transparent" className="badge-outline-secondary badge">CFN: {data.course_full_name_name}</Badge>
          }
        </div>
      </div>
    )
  } else {
    return <>-</>
  }
}

const CellExpoNames = ({ data }:{ data: any }) => {
  return (
    <div className="d-flex justify-content-start align-items-start flex-column gap-1">
      {
        (data.expo_names || []).map((name: string) => (
          <div className="d-flex">
            <Badge bg="secondary" className="fw-normal">
              {name}
            </Badge>
          </div>
        ))
      }
    </div>
  )
}

const columns = [
  {
    label: "Name",
    name: "full_name",
    width: "300px",
    CustomCell: (data: any) => <CellName data={data} />,
  },
  {
    label: "JSA ID",
    name: "leads_code",
    width: "200px",
    CustomCell: (data: any) => <CellID data={data} />,
  },
  // {
  //   label: "Course Level",
  //   name: "course_level_name",
  //   width: "150px",
  //   inputFilter: "select",
  //   filterOptions: {
  //     table: 'course_levels',
  //     column: 'name',
  //   }
  // },
  // {
  //   label: "Study Area",
  //   name: "course_major_name",
  //   width: "150px",
  //   inputFilter: "select",
  //   filterOptions: {
  //     table: 'course_majors',
  //     column: 'name',
  //   }
  // },
  // {
  //   label: "Course Full Name",
  //   name: "course_full_name_name",
  //   width: "200px",
  // },
  {
    label: "Comments/Notes",
    name: "note",
    // width: "300px",
    CustomCell: (data: any) => <CellComment data={data} />,
  },
];

const List = ({ 
  onClickAction,
}: { 
  onClickAction?: any;
 }) => {
  let { slug } = useParams();

  const [search, setSearch] = useState({});
  const [sort, setSort] = useState<object>({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  
  const [current, setCurrent] = useState();
  const [modalType, setModalType] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);

  const closeModal = () => setModalShow(false);
  const openModal = (type: string, data?: any) => {
    setCurrent(data)
    setModalType(type)
    setModalShow(true)
  };

  const {
    data: lists,
    isFetching: isFetchingMasterLeads,
    isLoading: isLoadingMasterLeads,
    isUninitialized: isUninitializedMasterLeads,
    isError: isErrorMasterLeads,
    error: errorMasterLeads,
    refetch: refetchMasterLeads,
  } = useGetExpoRegistrantQuery(
    {
      slug,
      search,
      sort,
      page: activePage,
      per_page: perPage,
    },
    {
      // pollingInterval: 100,
      refetchOnMountOrArgChange: true,
      // refetchOnFocus: true,
      refetchOnReconnect: true,
      // skip: false,
    }
  );

  return (
    <>
      <BTable 
        // tableClassname={'table-centered'}
        isLoading={ isFetchingMasterLeads || isLoadingMasterLeads || isUninitializedMasterLeads || isErrorMasterLeads }
        data={lists?.result}
        meta={lists?.meta}
        columns={columns}
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        activePage={activePage}
        setActivePage={setActivePage}
        perPage={perPage}
        setPerPage={setPerPage}
        onClickAction={onClickAction}
      />
    </>
  );
};

export default List;
