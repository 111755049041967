import React, { useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaskedInput from "react-text-mask";
import {
  VerticalForm,
  FormInput,
  FormDate,
  FormSelect,
  FormSelectAsync,
} from "components/";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";

import {
  useCheckExpoRegistrantMutation,
  useSearchExpoRegistrantMutation,
} from "services/api";
import { useEffectOnce } from "usehooks-ts";

const validation1 = yupResolver(
  yup.object().shape({
    leads_code: yup.string().label('JACK Study Abroad ID').required(''),
  })
);

const validation2 = yupResolver(
  yup.object().shape({
    full_name: yup.string().label('Full name').required(''),
    dob: yup.string().label('Date of birth').required(),
  })
);

const FormCheck = ({
  slug,
  booth,
  expo,
  layout,
  setLayout,
  setExpoRegistrant,
  expoRegistrant,
  onHide,
}: {
  slug?: string;
  expo?: any;
  booth?: any;
  layout: string;
  setLayout: any;
  setExpoRegistrant: any;
  expoRegistrant: any;
  onHide: any;
}) => {
  const [leadsCode, setLeadsCode] = useState('');
  const [fullName, setFullName] = useState('');
  const [birth, setBirth] = useState('');
  const [leadsIdNotFound, setLeadsIdNotFound] = useState<boolean>(false);
  const [nameDobNotFound, setNameDobNotFound] = useState<boolean>(false);
  const [noOptionsMessage, setNoOptionsMessage] = useState<string>('');
  

  const [searchLeads, { isLoading: isLoadingSearch }] = useSearchExpoRegistrantMutation();

  const [checkLeads, { isUninitialized: isUninitializedCheck, isLoading: isLoadingCheck, isSuccess: isSuccessCheck, isError: isErrorCheck}] = useCheckExpoRegistrantMutation();
  
  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setNoOptionsMessage('Search')
    if(inputValue.length >= 2 ){
      searchLeads({
        slug,
        search: inputValue,
        response_type: 'select-option',
      })
        .unwrap()
        .then((res: any) => {
          callback(res?.result);
          if(res.result.length == 0){
            setNoOptionsMessage('No data')
          }
        })
        .then((error: any) => {
          console.log("error");
          callback([]);
        });
    } else {
      setNoOptionsMessage('please type at least 3 letters')
    }
  };

  const methods1 = useForm({ resolver: validation1 });
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    control: control1,
    setValue: setValue1,
    setError: setError1,
    clearErrors: clearErrors1,
    getValues: getValues1,
    formState: { errors: errors1 },
    reset: reset1,
  } = methods1;

  const methods2 = useForm({ resolver: validation2 });
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    control: control2,
    setValue: setValue2,
    setError: setError2,
    clearErrors: clearErrors2,
    getValues: getValues2,
    formState: { errors: errors2 },
    reset: reset2,
  } = methods2;

  const onCheckLeadsID = async (values: any) => {
    await checkLeads({
      slug,
      leads_code: values.leads_code
    })
    .unwrap()
    .then((res: any) => {
      if(res.result){
        setLayout('expoRegistrant')
        setLeadsIdNotFound(false)
        setExpoRegistrant(res.result)
      } else {
        setExpoRegistrant({})
        setLeadsIdNotFound(true)
      }
    })
    .catch((error: any) => {
      setLeadsIdNotFound(true)
      console.log("error");
    });
  }

  const onCheckNameDob = async (values: any) => {
    await checkLeads({
      slug,
      full_name: values.full_name,
      dob: values.dob,
    })
    .unwrap()
    .then((res: any) => {
      if(res.result){
        setExpoRegistrant(res.result)
        setNameDobNotFound(false)
        setLayout('expoRegistrant')
      } else {
        setExpoRegistrant({})
        setNameDobNotFound(true)
      }
    })
    .catch((error: any) => {
      setNameDobNotFound(true)
      console.log("error");
    });
  }

  const onClearLeadsId = () => {
    reset1({
      leads_code: undefined
    });
    clearErrors1();
    setValue1('leads_code', '');
    setLeadsCode('');
    setLeadsIdNotFound(false);
  }

  const onClearNameDob = () => {
  }

  return (
    <div className="">
      <form noValidate>
        <Form.Group className="mb-2 mx-auto max-w-300px">
          {/* <Form.Label htmlFor="Button">JACK Study Abroad ID</Form.Label> */}
          <InputGroup>
            <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
            <Form.Control
              {...(register1 ? register1('leads_code') : {})}
              size="lg"
              placeholder="JACK Study Abroad ID"
              value={leadsCode}
              onChange={(e) => {
                setValue1('leads_code', e.target.value)
                clearErrors1('leads_code')
                setLeadsIdNotFound(false);
                setLeadsCode(e.target.value);
              }}
              disabled={isLoadingCheck}
              className={`form-control ${
                errors1 && errors1['leads_code'] ? "is-invalid" : ""
              }`}
              maxLength={20}
            />
            {leadsIdNotFound ? (
              <Button
                variant="dark"
                onClick={(e) => { onClearLeadsId() }}
                disabled={isLoadingCheck}
              >
                <i className="mdi mdi-close"></i>
              </Button>
            ) : (
              <Button
                variant="dark"
                type="button"
                onClick={handleSubmit1((values) => onCheckLeadsID(values))}
                disabled={isLoadingCheck}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "10px" }}
                >
                  {isLoadingCheck ? (
                    <ScaleLoader
                      height={10}
                      width={1}
                      radius={1}
                      margin={1}
                      color="#b7b1dd"
                    />
                  ) : (
                    <i className="mdi mdi-arrow-right"></i>
                  )}
                </div>
              </Button>
            )}
          </InputGroup>
          {errors1 && errors1['leads_code'] ? (
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors1['leads_code']["message"]}
            </Form.Control.Feedback>
          ) : null}
          {
            leadsIdNotFound &&
            <Alert variant="danger" style={{padding: '4px 20px', marginTop: '8px'}}>
               ID #{leadsCode} can't be found
            </Alert>
            // <div className="text-center mt-1 font-16 fst-italic text-danger-emphasis opacity-75">
            //   {leadsCode} ID not found
            // </div>
          }
        </Form.Group>
      </form>

      <div className="h-line my-4 mx-auto">
        <span>OR</span>
      </div>

      <form noValidate>
        <Form.Group className="mb-2 max-w-300px mx-auto">
          <FormSelectAsync
            className="w-100"
            // label="Full Name"
            name="full_name"
            placeholder="Full name (e.g. Dwi Puspitasari)"
            containerClass={"mb-3"}
            defaultValue={fullName ? {
              value: fullName,
              label: fullName
            } : undefined}
            defaultOptions={fullName ? [{
              value: fullName,
              label: fullName
            }] : undefined}
            loadOptions={loadOptions}
            // disabled={isLoading}
            register={register2}
            control={control2}
            errors={errors2}
            // setValue={setValue2}
            setError={setError2}
            clearErrors={clearErrors2}
            onChange={(option: any) => {
              console.log(option,'option')
              setValue2('full_name', option.value)
            }}
          />
          {/* <Form.Control
            {...(register2 ? register2('full_name') : {})}
            // size="lg"
            placeholder="Full name (e.g. Dwi Puspitasari)"
            value={fullName}
            onChange={(e) => {
              setValue2('full_name', e.target.value)
              clearErrors2('full_name')
              setFullName(e.target.value);
            }}
            disabled={isLoadingCheck}
            className={`form-control ${
              errors1 && errors1['full_name'] ? "is-invalid" : ""
            }`}
          /> */}
        </Form.Group>
        <Form.Group className="mb-2 max-w-300px mx-auto">
          <InputGroup>
            <MaskedInput
              {...(register2 ? register2('dob') : {})}
              mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
              placeholder="Date of Birth (dd-mm-yyyy)"
              className={`form-control ${
                errors2 && errors2['dob'] ? "is-invalid" : ""
              }`}
              // defaultValue={defaultValue}
              onChange={(e: any) => {
                setValue2('dob', moment(e.target.value, "DD-MM-YYYY").format("YYYY-MM-DD"));
                setBirth(moment(e.target.value, "DD-MM-YYYY").format("YYYY-MM-DD"));
                console.log(moment(e.target.value).isBetween('1900-01-01', moment().subtract(10, 'years').format('YYYY-MM-DD'), 'days'))
                if (moment(e.target.value).isBetween('1900-01-01', moment().subtract(10, 'years').format('YYYY-MM-DD'), 'days')) {
                  clearErrors2('dob');
                } else {
                  setError2('dob', { type: 'custom', message: 'Invalid date' })
                }
              }}
            />
            {leadsIdNotFound ? (
              <Button
                variant="dark"
                onClick={(e) => { onClearNameDob() }}
                disabled={isLoadingCheck}
              >
                <i className="mdi mdi-close"></i>
              </Button>
            ) : (
              <Button
                variant="dark"
                type="button"
                onClick={handleSubmit2((values) => onCheckNameDob(values))}
                disabled={isLoadingCheck}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "10px" }}
                >
                  {isLoadingCheck ? (
                    <ScaleLoader
                      height={10}
                      width={1}
                      radius={1}
                      margin={1}
                      color="#b7b1dd"
                    />
                  ) : (
                    <i className="mdi mdi-arrow-right"></i>
                  )}
                </div>
              </Button>
            )}
          </InputGroup>
          {
            nameDobNotFound &&
            <Alert variant="danger" style={{padding: '4px 20px', marginTop: '8px'}}>
              We couldn't find your ID
            </Alert>
            // <div className="text-start mt-1 font-16 fst-italic text-danger-emphasis opacity-75">
            //   We couldn't find your ID
            // </div>
          }
        </Form.Group>
      </form>
    </div>
  );
};

export default FormCheck;
